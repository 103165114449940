<template>
    <main class="flex-1 overflow-y-auto h-full">
        <PageHead sticky
                  title="Produktkategorien">
            <template #actions>
                <c-button :path="{ name: 'ProductCategoryAdd' }">Neu</c-button>
            </template>
        </PageHead>

        <List :items="categories"
              editRouteName="ProductCategoryEdit"/>

        <p class="p-6 text-center text-base text-gray-400">
            {{ categories.length }} Produktkategorieren wurden geladen.
        </p>
    </main>
</template>

<script>
import {mapState} from 'vuex';
import List from '@/components/List';

export default {
    name: 'ProductCategories',
    components: {List},
    computed: {
        ...mapState('products', ['categories']),
    },
};
</script>
